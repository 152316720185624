import { useEffect, useState } from "react";
// import Link from "next/link";
import { useSelector } from "react-redux";
import CustomPopup from "components/shared/modal/customPopup";
import { useRouter } from "next/router";
// import Image from "next/image";
import { useWindowSize } from "api/commonConstants";

const ReadNews = ({ postData = {} }) => {
  console.log("ReadNewsReadNews", postData);
  const size = useWindowSize();
  const router = useRouter();
  const { query } = router;
  const [loginPrompt, setLoginPrompt] = useState(false);

  const [postStateData, setPostStateData] = useState([]);
  const [firstScroll, setFirstScroll] = useState(false);
  const { postDetails } = useSelector(({ PostReducer }) => PostReducer);
  const pageToOpen =
    router.pathname === "/show/detail/[seriesSlug]"
      ? `/show/detail/${query?.seriesSlug}/${postData?.postSlug}`
      : postData?.type === "audio"
      ? postData?.permalink
      : `/${
          postData?.categorySlug?.toLowerCase() || query?.categoryName
        }/post/${postData?.postSlug || ""}`;

  useEffect(() => {
    if (
      postData?.postId
        ? postDetails?._id === postData?.postId
        : postDetails?._id === postData?._id
    ) {
      setPostStateData(postDetails);
    }
  }, [postDetails, postData?._id, postData?.postId]);

  useEffect(() => {
    setPostStateData(postData);
  }, [postData]);

  useEffect(() => {
    if (firstScroll === false) {
      function handleScroll() {
        setFirstScroll(true);
        window.removeEventListener("scroll", handleScroll);
      }

      window.addEventListener("scroll", handleScroll);
    }
  }, [firstScroll]);

  return (
    <>
      <div
        className="headline-box-sm"
        style={{
          border: "none !important",
          padding: "0px !important",
        }}
      >
        <div
          className={
            router?.pathname?.includes("show")
              ? "headline-left-col show-screen"
              : "headline-left-col"
          }
        >
          <div
            className="headline-pic-box"
            style={{ minHeight: "0% !important" }}
          >
            {postData.type === "liveblog" &&
              postData?.liveBlogData?.eventStatus === "live" && (
                <div
                  style={{
                    position: "absolute",
                    top: "-25px",
                    right: "0",
                    zIndex: "1",
                  }}
                >
                  <img
                    src="assets/images/giphy.gif"
                    width="60px"
                    height="80px"
                    alt="live"
                  ></img>
                </div>
              )}
            <a
              href={`${
               pageToOpen
              }`}
              passHref
            >
              <a className="headline-thumb">
                {postData && postData?.headingImageUrl && (
                  <>
                    <img
                      src={
                        firstScroll
                          ? postData?.headingImageUrl + "?width=200"
                          : postData?.headingImageUrl + "?width=70"
                      }
                      // layout="fill"
                      alt={postData?.imgAltText || "News pic"}
                      className={"small-card"}
                      loading="lazy"
                      //         sizes="(max-width: 1500px) 150vw,
                      //   (max-width: 768px) 70vw,
                      //   (max-width: 500px) 40vw,
                      //   33vw
                      //  "
                    />
                    <div className="news-type-icon">
                      <i
                        style={{ color: "white" }}
                        className={`icon-${
                          postData?.type === "text" ||
                          postData?.type === "liveblog"
                            ? "cat-read"
                            : postData?.type === "audio"
                            ? "mic"
                            : "play-circle"
                        }`}
                      ></i>
                    </div>
                  </>
                )}
              </a>
            </a>
          </div>
        </div>
        <div
          className="headline-right-col"
          style={{ position: "relative", paddingTop: "5px" }}
        >
          <div className="headline-body">
            <div className="headline-info">
              <div className="series-row"></div>
              <h3
                // style={{
                //   // fontFamily: "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                //   fontStyle: "normal",
                //   fontWeight: "500 !important",
                //   fontSize: "17px !important",
                //   lineHeight: "130%",
                //   paddingRight: "10px",
                // }}
              >
                <a
                  href={`${
                     pageToOpen
                  }`}
                  passHref
                >
                  <a>{postData?.title?.padEnd(50)}</a>
                </a>
              </h3>
            </div>
          </div>
        </div>
        <CustomPopup
          showModal={loginPrompt}
          onClose={() => setLoginPrompt(false)}
        />
      </div>
      {size.width < 500 && (
        <div
          className="line-newsImageTextBanner1 line-readNews"
          style={{
            height: "1px",
            background: "rgba(166, 166, 166, 0.2)",
            width: "100%",
          }}
        ></div>
      )}
    </>
  );
};
export default ReadNews;
